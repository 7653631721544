/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { getpageLang } from "../shared/extFunctions";
// import { Link } from "gatsby";
import { useRef, useEffect, useCallback } from "react";

/* -------------------------------  Import pages ---------------------------- */
/* -------------------------------  Local Images ---------------------------- */
import downArrow from "../images/downArrow.png";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function YearOfFunds({ data }) {
	// const lang = getpageLang(data.lang);
	// const {title, header, years} = lang === "en" ? jsonData.en.funds : jsonData.fr.funds;
	const { year, funds } = data.year;

	if (typeof document !== "undefined") {
		setTimeout(() => {
			// code Here!
		}, 200);
	} else {
		console.warn("papish famma mochkla f document/window variable", "Funds");
	}

	return (
		<>
			<AyearOfFunds>
				<div className={"container " + year} id={"c" + year}>
					<label className="bar gothicFont" htmlFor={"toggle" + year} onClick={() => checkboxToggled(year, funds.length)} >
						{year}
						<input className="checkbox" type="checkbox" name="toggle" id={"toggle" + year} />
						<img src={downArrow} alt="arrow down" />
					</label>
					<div className="rows robotoFont">
						<OneRow row={data.header} className="headerRow" />
						<div id={"rowsC"+year}>
							{funds.map((row, index) => (
								<OneRow key={"Row" + Math.floor(Math.random() * 5000)} row={row} index={index} />
							))}
						</div>
					</div>
				</div>
			</AyearOfFunds>
		</>
	);
}
export default YearOfFunds;
/* ---------------------------- Styled Components --------------------------- */
const AyearOfFunds = styled.section`
	margin-top: 1rem;
	.bar {
		img {
			height: 35px;
			filter: invert(1);
			transition: all 0.2s ease-in-out .2s;
		}
		background-color: #f5f2ee;
		border: none;
		width: 100%;
		height: 60px;

		display: grid;
		grid-template-columns: 1fr 50px;
		align-items: center;

		text-align: center;
		font-size: 1.5rem;
		font-weight: 600;
		/* padding: .3rem 0; */
		transition: all 0.2s;
		&:hover {
			background-color: #e8e1d8;
		}
		.checkbox {
			display: none;
		}
		.checkbox:checked + img {
			transform: rotate(180deg);
			/* img{
            } */
		}
	}
	.container {
		max-height: 60px;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		display: grid;
		box-shadow: 0px 3px 6px #00000029;
		grid-template-columns: 1fr;
		background-color: #fdf7ef3b; //#E1D8CC80;
		&.expanded {
			border: 1px solid #e1e1e170;
			box-shadow: none;
			max-height: 1000px;
			.bar {
				box-shadow: 0px 3px 6px #00000029;
			}
		}
	}
	.rows {
		.row {
			display: grid;
			grid-template-columns: 2fr 1fr 1fr 1.5fr;
			column-gap: 0.5rem;
			font-size: 1.25rem;
			height: fit-content;
			padding: 0 1rem;
			transition: background-color 0.2s;
			word-break: normal;
			// TODO: add hyphen or some shit here
			&:hover {
				background-color: #0000000a;
			}
		}
		& > .row {
			display: none;
			font-weight: bold;
		}
		@media screen and ((max-device-width: 700px) or (max-width: 700px)) {
			.row {
				display: grid;
				grid-template-columns: 1fr;
				grid-template-rows: 1.5fr 1fr 1fr 1.5fr;
				height: 20rem;
				font-size: 1rem;
				align-items: start;
				text-align: left;
			}
			& > div {
				height: 20rem;
				overflow-y: hidden;
				scroll-snap-type: y mandatory;
				&:has(.row1) {
					overflow-y: scroll;
				}
				.row {
					scroll-snap-align: start;
				}
				/* !important is needed sometimes */
				&::-webkit-scrollbar {
					width: 10px !important;
				}

				/* Track */
				&::-webkit-scrollbar-track {
					/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important; */
					/* -webkit-border-radius: 10px !important;
                border-radius: 10px !important; */
					background-color: #e1d8cc;
					box-shadow: inset 0 0 0 4px #fff !important;
				}

				/* Handle */
				&::-webkit-scrollbar-thumb {
					/* -webkit-border-radius: 10px !important; */
					/* border-radius: 10px !important; */
					box-shadow: inset 0 0 0 2px #fff !important;
					background: #e8e1d8 !important;
				}
				&::-webkit-scrollbar-thumb:window-inactive {
					box-shadow: inset 0 0 0 2px #fff !important;
					background: #e8e1d8 !important;
				}
			}
			display: grid;
			grid-template-columns: 1fr 2fr;
			position: relative;
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 10px;
				width: 35%;
				background-color: #e1d8cc;
			}
		}
	}
`;
// funds margin bottom ✅
// remove border on .container ✅
// faster transition on .container✅
// on hover for each row✅

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
const OneRow = (row) => {
	const { name, amount, date, project } = row.row;
	// console.log("row",row,name,row.index)
	return (
		<>
			<div className={"row row" + row.index}>
				<p>{name} </p>
				<p>{amount} </p>
				<p>{date} </p>
				<p>{project} </p>
			</div>
		</>
	);
};
function checkboxToggled(year, n) {
	console.log(year);
	if (typeof document !== "undefined") {
		setTimeout(() => {
			// code Here!
			throttleExpand(year,n);
		}, 200);
	} else {
		console.warn("papish famma mochkla f document/window variable", "Funds");
	}
}
const throttleExpand = throttle(expand, 100);
function expand(year,n) {
	const containerElem = document.querySelector("#c" + year);
	const checkboxElem = document.querySelector("#toggle" + year);
	const rowsCElem = document.querySelector("#rowsC"+year)
	if (checkboxElem.checked) {
        console.log(year,"checked")
		containerElem.classList.add("expanded");
        sessionStorage.setItem(year+"i",n );
        throttleScrollingDown(320,year);

		sessionStorage.setItem("posY"+year,0);
		rowsCElem.addEventListener("scroll",()=>{
			sessionStorage.setItem("posY"+year,rowsCElem.scrollTop);
		})
    } else {
        console.log(year,"unchecked")
		containerElem.classList.remove("expanded");
        sessionStorage.setItem(year+"i",1 );

	}
}
const throttleScrollingDown = throttle(scrollingdown,100);
function scrollingdown( scrollTo,year) {
	setTimeout(() => {
		// code Here!
        const i =sessionStorage.getItem(year+"i");
		console.log(i,")\tscrollingDown"+year, scrollTo);
        const elem= document.querySelector("#rowsC"+year);
		if (scrollTo-320!=sessionStorage.getItem("posY"+year)) return console.log("outed",sessionStorage.getItem("posY"+year),scrollTo)
        // if (typeof elem !== "undefined") return console.log("nikomek feregh");
		if (i == 1) {
			elem.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			return;
		}
		elem.scrollTo({
			top: scrollTo,
			left: 0,
			behavior: "smooth",
		});
        sessionStorage.setItem(year+"i",i-1);
		throttleScrollingDown( scrollTo + 320, year);
	}, 2500);
}
function throttle(func, wait) {
    let timeout;
    
        return function() {
            const context = this;
            const args = arguments;
        
            if (!timeout) {
                timeout = setTimeout(() => {
                    timeout = null;
                    func.apply(context, args);
                }, wait);
            }
        };
    }
