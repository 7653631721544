/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
// import { Link } from "gatsby";


/* -------------------------------  Import pages ---------------------------- */

/* -------------------------------  Local Images ---------------------------- */

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function PartnerCard({data}) {
    const {name, image, link}= data.card;
    // const lang = getpageLang(data.lang);
    // const content = lang === "en" ? jsonData.en : jsonData.fr;
    if (link==="") return (
        <CardContainer id="partners">
            <a className="cardContainerUrl" >
                <div className="imgContainer">
                    <img src={image} alt={name} srcSet="" />
                </div>
                <div className="partnerName robotoFont">{name}
                    <div></div>
                </div>
            </a>
        </CardContainer>
    );
    if (link!=="") return (
        <CardContainer id="partners">
            <a className="cardContainerUrl" href={link} target="_blank" rel="noopener noreferrer">
                <div className="imgContainer">
                    <img src={image} alt={name} srcSet="" />
                </div>
                <div className="partnerName robotoFont">{name}
                    <div></div>
                </div>
            </a>
        </CardContainer>
    );
}
export default PartnerCard;
/* ---------------------------- Styled Components --------------------------- */ 
const CardContainer = styled.div`
    /* width: 16rem;
    height: 18rem; */
    height: calc(18vw);
    width: 17vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .cardContainerUrl{
        text-decoration:none;
        color:#000;
        width:100%;
        height:100%;
        display: grid;
        grid-template-columns:1fr;
        grid-template-rows: 2fr 1fr;
        /* display: flex;
        flex-direction:column; */
        gap:1rem;
        .imgContainer{
            height:calc(18vw * 2 / 3);
            width:100%;
            background-color:#F9F7F7;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                max-height: calc(18vw * 2 / 3);
                /* max-width: 17vw;    */
                
                max-width: 100%;  
                object-fit:contain;
                margin: 0 auto;
            }
        }
        .partnerName{
            margin:0 1rem;
            width:fit-content;
            transition: all .5s;
            position:relative;
            font-size:1rem;            
            @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
                font-size:.75rem
        
            }
            div{
                height:3px;
                width: calc(100% + 10px);
                max-width: 14rem;
                background-color:#E1D8CC;
                /* transition: all .5s;
                position:absolute;
                bottom:-7px;
                z-index:-1;
                border-radius:2px; */
            }
        }
    }
    @media screen and ((max-device-width: 1400px) or (max-width: 1400px)) {
        height: calc( 22vw );
        width: 21vw;
        .cardContainerUrl{
            .imgContainer{
                height:calc(22vw * 2 / 3);
                img{
                    max-height: calc(22vw * 2 / 3);
                    max-width: 100%;  
                    /* object-fit:contain; */
                }
            }
        }
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        height: calc(42vw);
        width: 40vw;
        .cardContainerUrl{
            .imgContainer{
                height:calc(42vw * 3 / 5);
                img{
                    max-height: calc(42vw * 3 / 5);
                    max-width: 100%;  
                    object-fit:contain;
                }
            }
        }
    }
`;

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */