/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { getpageLang } from "../shared/extFunctions";
// import { Link } from "gatsby";
import { useRef, useEffect, useCallback } from 'react';
import jsonData from "../shared/json/partnersAndFunds.json";

/* -------------------------------  Import pages ---------------------------- */
import YearOfFunds from "./yearOfFunds";
/* -------------------------------  Local Images ---------------------------- */
import downArrow from "../images/downArrow.png";
import au3 from "../images/aboutUsPage/low/au3.jpg";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Funds({data}) {
    const lang = getpageLang(data.lang);
    const {title, header, years} = lang === "en" ? jsonData.en.funds : jsonData.fr.funds;
    
    if (typeof document !== "undefined") {
        setTimeout(() => {
            // code Here!
        }, 200);
    } else {
        console.warn(
            "papish famma mochkla f document/window variable",
            "Funds"
        );
    }
    return (
    <>
        <FundsContainer id="funds">
            <h1 id="title" className="gothicFont" >{title} </h1>
            <div className="Years">
                <HeaderRow className="robotoFont">                    
                    <p>{header.name} </p>
                    <p>{header.amount} </p>
                    <p>{header.date} </p>
                    <p>{header.project} </p>
                </HeaderRow>
                {
                    years.map((year)=> <YearOfFunds key={"Year"+(Math.floor(Math.random() * 5000))} data={{year:year, header:header}}/> )
                }
            </div>
            <div className="cornerBox topRight"></div>
            <div className="cornerBox bottomLeft"></div>
        </FundsContainer>        
    </>
    );
}
export default Funds;
/* ---------------------------- Styled Components --------------------------- */
const FundsContainer = styled.section `
    
    position: relative;
    margin:15vh 0;
    width:90vw;
    height:fit-content;
    margin-left:5vw;
    padding-bottom: 2vh;
    background-color: #fff;
    #title{
        font-size:3rem;
        font-weight: bold;
        margin:0;
        margin-bottom:2rem;
    }
    .cornerBox{
        background-color:#fff;
        width: 50px;
        height: 50px;
        position: absolute;
        z-index:-1;
        &.topRight{
            box-shadow:  #000 2px -2px 0px 2px;
            top:-5px;
            right:-15px;
        }
        &.bottomLeft{
            box-shadow:  #000 -2px 2px 0px 2px;
            bottom: -15px;
            left:-25px;
        }
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        .cornerBox{
            display: none;
        }
    }
`;
const HeaderRow = styled.div`
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: 2fr 1fr 1fr 1.5fr;
    column-gap: .5rem;

    font-size: 1.5rem;
    font-weight: 600;

    height: fit-content;
    padding:0 1rem;
    /* max-width: 90vw; */
    position: relative;
    p{
        margin-bottom:.5rem;
    }
    &::after{
        content:"";
        position:absolute;
        left:0;
        bottom: 0;

        width:90vw;
        height:.5rem;

        background-color:#E1D8CC;
        box-shadow: inset 0px 3px 6px #00000029;

    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        display: none;
    }
`;

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
