/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ---------------------------------  Packages ------------------------------ */
import * as React from "react";
// import Helmet from "react-helmet";
import styled from "styled-components";


/* -------------------------  Import pages/ components ---------------------- */
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import { SEO } from "../components/seo.jsx";
import FloatingSocials from "../LandingPageComponents/floatingSocials.js";
import Partners from "../pantersFundsComponents/partners.jsx";
import Funds from "../pantersFundsComponents/funds.jsx";

import "./style.scss";
/* -------------------------------  Local Images ---------------------------- */
import minLogo from "../shared/A.png";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function PartnersAndFunds({location, data}) {  
  const params = new URLSearchParams(location.search);
  // console.log("PARAMS:",params)
  const lang= params.get("lang");
  // console.log(location);

    return (
      <>
        <Header data={{lang: lang}} />
        <Partners data={{lang: lang}} />
        <Funds data={{lang: lang}} />
        <FloatingSocials data={{lang:lang}} />
        {/* <Filler/> */}
        

        <Footer data={{lang:lang}} />
      </>
  );
}
export default PartnersAndFunds;

export const Head = () => (
  <>
      <link rel="icon" type="image/x-icon" href={minLogo}/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <SEO title="Partners & Funds - ATDCE"/>

  </>
);
/* ---------------------------- Styled Components --------------------------- */
const Filler= styled.div`
  width: 1px;
  height: 250vh;
`;

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */