/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { getpageLang } from "../shared/extFunctions";
// import { Link } from "gatsby";

import jsonData from "../shared/json/partnersAndFunds.json";

/* -------------------------------  Import pages ---------------------------- */
import PartnerCard from "./partnerCard";
/* -------------------------------  Local Images ---------------------------- */
import au3 from "../images/aboutUsPage/low/au3.jpg";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Partners({data}) {
    const lang = getpageLang(data.lang);
    const content = lang === "en" ? jsonData.en : jsonData.fr;
    return (
    <>
        <PartnersContainer>
            <h1 id="title" className="gothicFont" >{content.partnersText.title} </h1>
            <h3 id="subtitle" className="gothicFont" >{content.partnersText.subtitle} </h3>

            <h4 className="partnerType robotoFont">{content.partnersText.gov} </h4>
            <div className="containerOfAllCards">
                {
                    content.partners.map((partner)=>{
                        if(partner.gov){
                            return <PartnerCard key={"partner"+(Math.floor(Math.random() * 5000))} data={{card: partner}} />                        
                        }
                    })
                }
            </div>

            <h4 className="partnerType robotoFont">{content.partnersText.brands} </h4>
            <div className="containerOfAllCards">
                {
                    content.partners.map((partner)=>{
                        if(!partner.gov){
                            return <PartnerCard key={"partner"+(Math.floor(Math.random() * 5000))} data={{card: partner}} />                        
                        }
                    })
                }
            </div>

        </PartnersContainer>        
    </>
    );
}
export default Partners;
/* ---------------------------- Styled Components --------------------------- */
const PartnersContainer = styled.section` 
    /* background-color: blue; */
    position: relative;
    margin-top:15vh;
    /* margin-top: 15vh; */
    width:90vw;
    height:fit-content;
    padding-left:5vw;
    #title{
        font-size:3rem;
        font-weight: bold;
        margin:0;
    }
    #subtitle{
        font-size:1.5rem;
        margin-top:0;
        margin-bottom:1rem;
    }
    .partnerType{
        font-size:1.5rem;
        font-weight:500;
        margin-top:4rem;
    }
    .containerOfAllCards{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        /* grid-template-columns: repeat(auto-fill,minmax(16em,1fr) ); */
        row-gap: 1.5rem;
        @media screen and ((max-device-width: 1400px) or (max-width: 1400px)) {
            grid-template-columns: repeat(4,1fr);
            row-gap: 1.5rem;
            column-gap:.5rem;    
        }
        @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
            grid-template-columns: repeat(2,1fr);
            row-gap: 1.5rem;
            column-gap:.5rem;    
        }
    }
`; 

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */